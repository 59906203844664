import { IS_TESTNET } from "../is-testnet";

const CURTIS_TESTNET_TOKEN_LIST = [
	{
		id: "33111:0x543F85968B514F46b921988BD07577642d8aaE7F",
		address: "0x543F85968B514F46b921988BD07577642d8aaE7F",
		chainId: 33111,
		decimals: 18,
		logoURI: "/images/tokens/wape.svg",
		name: "Wrapped ApeCoin",
		symbol: "WAPE",
	},
	{
		id: "33111:0x24D22B18Bc00ef7BFB402f46BAdBaB33635ACfB7",
		address: "0x24D22B18Bc00ef7BFB402f46BAdBaB33635ACfB7",
		chainId: 33111,
		decimals: 18,
		logoURI: "",
		name: "TestToken",
		symbol: "TT",
	},
	{
		id: "33111:0x1570aAb6A9BF100b6349d5b4e49816bcb069A164",
		address: "0x1570aAb6A9BF100b6349d5b4e49816bcb069A164",
		chainId: 33111,
		decimals: 18,
		logoURI: "https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/token/usdc.jpg",
		name: "USDC Coin",
		symbol: "USDC",
	},
	{
		id: "33111:0x6e7812D8E37bfd3453Ca73D1B3fDA10F430E875f",
		address: "0x6e7812D8E37bfd3453Ca73D1B3fDA10F430E875f",
		chainId: 33111,
		decimals: 18,
		logoURI: "https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/token/usdt.jpg",
		name: "Tether",
		symbol: "USDT",
	},
	{
		id: "33111:0x164fd45F27458CEbc695bEaAb25e6DA1356d0129",
		address: "0x164fd45F27458CEbc695bEaAb25e6DA1356d0129",
		chainId: 33111,
		decimals: 18,
		logoURI: "https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/token/dai.jpg",
		name: "Dai Stablecoin",
		symbol: "DAI",
	},
	{
		id: "33111:0x22Da16191AEb9D0764D642A9AA9571A4599C982b",
		address: "0x22Da16191AEb9D0764D642A9AA9571A4599C982b",
		chainId: 33111,
		decimals: 18,
		logoURI:
			"https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/optimism/0x68f180fcCe6836688e9084f035309E29Bf0A2095.jpg",
		name: "Wrapped BTC",
		symbol: "WBTC",
	},
	{
		id: "33111:0xF1eA96B44E886c7F33577A7842ae30E33D1b9913",
		address: "0xF1eA96B44E886c7F33577A7842ae30E33D1b9913",
		chainId: 33111,
		decimals: 18,
		logoURI:
			"https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/bsc/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.jpg",
		name: "Wrapped BNB",
		symbol: "WBNB",
	},
];

const APECHAIN_MAINNET_TOKEN_LIST = [
	{
		id: "33139:0x48b62137EdfA95a428D35C09E44256a739F6B557",
		address: "0x48b62137EdfA95a428D35C09E44256a739F6B557",
		chainId: 33139,
		decimals: 18,
		logoURI: "/images/tokens/wape.svg",
		name: "Wrapped ApeCoin",
		symbol: "WAPE",
	},
	{
		id: "33139:0xA2235d059F80e176D931Ef76b6C51953Eb3fBEf4",
		address: "0xA2235d059F80e176D931Ef76b6C51953Eb3fBEf4",
		chainId: 33139,
		decimals: 18,
		logoURI: "/images/tokens/ape-usd.svg",
		name: "Ape USD",
		symbol: "ApeUSD",
	},
	{
		id: "33139:0xcF800F4948D16F23333508191B1B1591daF70438",
		address: "0xcF800F4948D16F23333508191B1B1591daF70438",
		chainId: 33139,
		decimals: 18,
		logoURI: "/images/tokens/ape-eth.svg",
		name: "Ape ETH",
		symbol: "ApeETH",
	},
	{
		id: "33139:0xFC2744A6Db0f97c606Df786b97255DFf6F27E320",
		address: "0xFC2744A6Db0f97c606Df786b97255DFf6F27E320",
		chainId: 33139,
		decimals: 18,
		logoURI: "",
		name: "Curtis",
		symbol: "CURTIS",
	},
	{
		id: "33139:0x0cB2424d147BeE383CdcD864e0D7139D78f0f717",
		address: "0x0cB2424d147BeE383CdcD864e0D7139D78f0f717",
		chainId: 33139,
		decimals: 18,
		logoURI: "",
		name: "Planet of the Apes",
		symbol: "POA",
	},
	{
		id: "33139:0x2C7A31a9b44Cd9c485314008B3F638758E6A8470",
		address: "0x2C7A31a9b44Cd9c485314008B3F638758E6A8470",
		chainId: 33139,
		decimals: 18,
		logoURI: "",
		name: "Blue",
		symbol: "BLUE",
	},
	{
		id: "33139:0xc7689ac46BC7a2c2819F0d9F280DC09C43295aBA",
		address: "0xc7689ac46BC7a2c2819F0d9F280DC09C43295aBA",
		chainId: 33139,
		decimals: 18,
		logoURI: "",
		name: "NOT A CULT",
		symbol: "CULT",
	},
	{
		id: "33139:0x493a7A762Db6bb2A1CdC526697d37Ff9d32b0473",
		address: "0x493a7A762Db6bb2A1CdC526697d37Ff9d32b0473",
		chainId: 33139,
		decimals: 18,
		logoURI: "",
		name: "BORED",
		symbol: "BORED",
	},
];

export const WAPE = IS_TESTNET
	? CURTIS_TESTNET_TOKEN_LIST.find((t) => t.symbol.toUpperCase() === "WAPE")
	: APECHAIN_MAINNET_TOKEN_LIST.find((t) => t.symbol.toUpperCase() === "WAPE");

export const DEFAULT_TOKEN_LIST = IS_TESTNET ? CURTIS_TESTNET_TOKEN_LIST : APECHAIN_MAINNET_TOKEN_LIST;
